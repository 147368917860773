import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import withRouter from "components/utils/withRouter.js";
import styles from "styles/public.module.css";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useSnackbar } from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Authenticator from "services/Authenticator.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function Login(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loginViewState, setLoginViewState] = useState(0);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpTimer, setShowOtpTimer] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);
  let timer;

  const checkIsAuthenticated = () => {
    if (Authenticator.isAuthenticated()) {
      const startViewPath = cookies.get("start_view_path");
      window.location = startViewPath;
    }
  };

  useEffect(() => {
    checkIsAuthenticated();

    // Cleanup the timer when the component unmounts
    return () => {
      stopOtpTimer();
    };
  }, []);

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  async function handleLogin(data) {
    setIsFormProcessing(true);

    if (loginViewState === 0) {
      try {
        const captchaToken = await executeRecaptcha("Verify_Credentials");
        const { transactionId } = await Authenticator.verifyCredentials(
          data.email,
          data.password,
          captchaToken
        );
        setTransactionId(transactionId);
        setLoginViewState(1);
        startOtpTimer();
        enqueueSnackbar(
          "Enter the OTP sent to your email",
          SnackbarConstants.success
        );
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        const captchaToken = await executeRecaptcha("Authenticate_User");

        const { navigateTo } = await Authenticator.authenticate(
          data.email,
          data.password,
          data.otp,
          transactionId,
          captchaToken
        );

        props.navigate(navigateTo);
      } catch (error) {
        handleError(error);
      }
    }

    setIsFormProcessing(false);
  }

  async function resendOTP() {
    setIsFormProcessing(true);
    startOtpTimer();

    try {
      const captchaToken = await executeRecaptcha("Resend_OTP");

      const data = getValues();

      const { transactionId } = await Authenticator.resendOTP(
        data.email,
        data.password,
        captchaToken
      );
      setTransactionId(transactionId);
      enqueueSnackbar("OTP resent", SnackbarConstants.success);
    } catch (error) {
      stopOtpTimer();
      handleError(error);
    }
    setIsFormProcessing(false);
  }

  function startOtpTimer() {
    setShowOtpTimer(true);
    setTimerSeconds(30);

    timer = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds == 0) {
          stopOtpTimer();
        }
        // Decrement the seconds by 1
        return prevSeconds - 1;
      });
    }, 1000); // Update every 1000ms (1 second)
  }

  function stopOtpTimer() {
    setShowOtpTimer(false);
    clearInterval(timer);
  }

  return (
    <Paper elevation={0} className={styles["form-container"]}>
      <h4>Login</h4>
      <form
        className="mt-4"
        onSubmit={handleSubmit(handleLogin)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleLogin(getValues());
          }
        }}
      >
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">
            Email ID
          </label>
          <input
            type="email"
            className="form-control"
            id="emailInput"
            name="email"
            aria-describedby="emailHelp"
            autoComplete="username"
            {...register("email", {
              required: "Email ID is required",
              pattern: {
                value: RegexUtils.EMAIL,
                message: "Email ID is invalid",
              },
            })}
          />
          <InputErrorMessage name="email" errors={errors} />
        </div>
        <div className="mb-3">
          <label htmlFor="passwordInput" className="form-label">
            Password
          </label>
          <OutlinedInput
            name="password"
            id="passwordInput"
            className={styles["password-input"]}
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                {" "}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            {...register("password", {
              required: "Password is required",
            })}
          />
          <InputErrorMessage name="password" errors={errors} />
        </div>
        {loginViewState === 1 ? (
          <div className="mb-3">
            <label htmlFor="otpInput" className="form-label">
              OTP
            </label>
            <input
              type="number"
              name="otp"
              className="form-control"
              id="otpInput"
              {...register("otp", {
                required: "OTP is required",
                pattern: {
                  value: RegexUtils.OTP,
                  message: "OTP is invalid",
                },
              })}
            />
            <InputErrorMessage name="otp" errors={errors} />
          </div>
        ) : null}
        <div className="text-end mb-2">
          {loginViewState == 0 ? (
            <Link
              component="button"
              onClick={() => props.navigate("/account/reset-password")}
              underline="none"
            >
              Forgot Password?
            </Link>
          ) : showOtpTimer ? (
            <small>Resend OTP in {timerSeconds} s</small>
          ) : (
            <Link component="button" onClick={resendOTP} underline="none">
              Resend OTP
            </Link>
          )}
        </div>
        {isFormProcessing ? (
          <LoadingButton loading variant="contained" className="me-3">
            {getSubmitButtonText()}
          </LoadingButton>
        ) : (
          <Button type="submit" className="me-3" variant="contained">
            {getSubmitButtonText()}
          </Button>
        )}
        <Button onClick={() => props.navigate(0)} variant="outlined">
          Cancel
        </Button>
      </form>
    </Paper>
  );

  function getSubmitButtonText() {
    if (loginViewState === 0) return "Login";
    return "Verify";
  }

  function handleError(error) {
    let errorData = error.data;
    if (error.data) {
      enqueueSnackbar(errorData.message, SnackbarConstants.error);
      let errors = errorData.errors;
      if (errors) {
        errors.forEach((item, i) => {
          enqueueSnackbar(item, SnackbarConstants.error);
        });
      }
    } else {
      enqueueSnackbar("Oops! Something went wrong", SnackbarConstants.error);
    }
    setIsFormProcessing(false);
  }
}

export default withRouter(Login);
