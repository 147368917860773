import { useState, useEffect, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import EnhancedTable from "components/utils/Table.js";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "components/utils/Tabs.js";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useParams } from "react-router";
import PatientService from "services/PatientService.js";
import UserService from "services/UserService.js";
import SaveIcon from "@mui/icons-material/Save";
import FormUtils from "utils/FormUtils.js";
import EnumUtils from "utils/EnumUtils.js";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";
import COUNTRY_CODES_LIST from "utils/constants/CountryCodesList.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import StringUtils from "utils/StringUtils.js";
import PopUpModal from "components/utils/PopUpModal.js";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ZipcodeService from "services/ZipcodeService.js";
import ZipcodeUtils from "utils/ZipcodeUtils.js";
import useAPI from "hooks/useAPI.js";
import DateUtils from "utils/DateUtils.js";
import { useOutletContext } from "react-router-dom";

function PatientProfile() {
  const [hospitalId] = useOutletContext();
  const { patientId } = useParams();

  const tabItemNames = ["Basic Details", "Biometrics", "Forms", "Documents"];
  const tabViews = [
    <BasicDetails patientId={patientId} hospitalId={hospitalId} />,
    <Biometrics patientId={patientId} hospitalId={hospitalId} />,
    <Forms patientId={patientId} hospitalId={hospitalId} />,
    <Documents patientId={patientId} hospitalId={hospitalId} />,
  ];

  return (
    <div>
      <Tabs itemNames={tabItemNames} views={tabViews} />
    </div>
  );
}

function BasicDetails(props) {
  const [hospitals, setHospitals] = useState([]);
  const ageInputRef = useRef();
  const [patientParentList, setPatientParentList] = useState([]);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  // Fetching list of hospitals
  useAPI(UserService.getHospitals, getHospitalsHandler, false, true);

  const getPatientProfileAPI = useAPI(
    PatientService.getPatient,
    getPatientHandler
  );
  const updatePatientDetailsAPI = useAPI(
    PatientService.updatePatientDetails,
    updatePatientDetailsHandler,
    true
  );
  const updatePatientEmailAPI = useAPI(
    PatientService.updatePatientEmail,
    () => {},
    true
  );
  const getPatientParentsAPI = useAPI(
    PatientService.getPatientParents,
    getPatientParentsHandler
  );
  const updateLocationAPI = useAPI(
    ZipcodeService.getLocationDetails,
    updateLocationHandler
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    getPatientProfileAPI.execute(props.hospitalId, props.patientId);
    getPatientParents();
  }, [hospitals]);

  /* Utility Function */

  function updateLocation(e) {
    let zipcode = e.target.value;
    updateLocationAPI.execute(zipcode);
  }

  function updatePatientDetails(data) {
    updatePatientDetailsAPI.execute(props.hospitalId, props.patientId, data);
  }

  function updatePatientEmail() {
    updatePatientEmailAPI.execute(props.hospitalId, props.patientId, {
      email: getValues("email"),
    });
  }

  function getPatientParents() {
    getPatientParentsAPI.execute(props.hospitalId, props.patientId);
  }

  /* API Success Handlers */

  function getPatientHandler(response) {
    let data = response.data;
    if (data["maritalStatus"] == null) data["maritalStatus"] = "";

    //set age input
    if (ageInputRef.current) {
      // checking if ageInputRef.current is not null, as it is causing error when view is changed immediately
      ageInputRef.current.value = StringUtils.getAgeFromDOB(data.dob);
    }

    reset(data);
  }

  function getHospitalsHandler(response) {
    let hospitalList = response.data;
    setHospitals(hospitalList);
  }

  function updateLocationHandler(response) {
    const { city, state, country } = ZipcodeUtils.getLocation(response.data);
    setValue("city", city);
    setValue("state", state);
    setValue("country", country);
  }

  function updatePatientDetailsHandler(response) {
    //set age input
    ageInputRef.current.value = StringUtils.getAgeFromDOB(response.data.dob);
  }

  function getPatientParentsHandler(response) {
    setPatientParentList(response.data);
  }

  return (
    <Paper elevation={0} className="container py-4 px-4">
      <form onSubmit={handleSubmit(updatePatientDetails)}>
        <div className="row mb-4">
          <div className="col col-4 mb-3">
            <label htmlFor="firstNameInput" className="form-label">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              id="firstNameInput"
              {...register("firstName", {
                required: "First Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Fist Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="firstName" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="lastNameInput" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              id="lastNameInput"
              {...register("lastName", {
                required: "Last Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Last Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="lastName" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="emailInput" className="form-label">
              Email ID
            </label>
            <OutlinedInput
              id="emailInput"
              name="email"
              type="text"
              size="small"
              sx={{ width: "100%" }}
              disabled={!isEditingEmail}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (isEditingEmail) {
                        updatePatientEmail();
                      }

                      setIsEditingEmail(!isEditingEmail);
                    }}
                    edge="end"
                    size="small"
                  >
                    {updatePatientEmailAPI.status === "pending" ? (
                      <CircularProgress size="20px" />
                    ) : isEditingEmail ? (
                      <DoneIcon fontSize="inherit" />
                    ) : (
                      <EditIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              {...register("email", {
                required: "Email ID is required",
                pattern: {
                  value: RegexUtils.EMAIL,
                  message: "Email ID is invalid",
                },
              })}
            />
          </div>
          <div className="col col-3 mb-3">
            <label htmlFor="dateOfBirthInput" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              name="dob"
              className="form-control"
              id="dateOfBirthInput"
              {...register("dob", {
                required: "DOB is required",
              })}
            />
            <InputErrorMessage name="dob" errors={errors} />
          </div>
          <div className="col col-2 mb-3">
            <label htmlFor="ageInput" className="form-label">
              Age
            </label>
            <input
              type="number"
              className="form-control"
              id="ageInput"
              ref={ageInputRef}
              disabled
            />
          </div>
          <div className="col col-3 mb-3">
            <label className="form-label d-block">Gender</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="genderInputMale"
                value="Male"
                {...register("gender", {
                  required: "Gender is required",
                })}
              />
              <label className="form-check-label" htmlFor="genderInputMale">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="genderInputFemale"
                value="Female"
                {...register("gender", {
                  required: "Gender is required",
                })}
              />
              <label className="form-check-label" htmlFor="genderInputFemale">
                Female
              </label>
            </div>
            <InputErrorMessage name="gender" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="maritalStatusInput" className="form-label">
              Marital Status
            </label>
            <select
              className="form-select"
              name="maritalStatus"
              id="maritalStatusInput"
              aria-label="Default select example"
              {...register("maritalStatus", {
                required: "Marital Status is required",
              })}
            >
              <option></option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="NA">NA</option>
            </select>
            <InputErrorMessage name="maritalStatus" errors={errors} />
          </div>
          <div className="col col-5 mb-3">
            <label className="form-label d-block">Age Group</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="ageGroup"
                id="ageGroupChildInput"
                value="Child"
                {...register("ageGroup")}
                disabled
              />
              <label className="form-check-label" htmlFor="ageGroupChildInput">
                Child
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="ageGroup"
                id="ageGroupAdolescentInput"
                value="Adolescent"
                {...register("ageGroup")}
                disabled
              />
              <label
                className="form-check-label"
                htmlFor="ageGroupAdolescentInput"
              >
                Adolescent
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="ageGroup"
                id="ageGroupAdultInput"
                value="Adult"
                {...register("ageGroup")}
                disabled
              />
              <label className="form-check-label" htmlFor="ageGroupAdultInput">
                Adult
              </label>
            </div>
          </div>
          <div className="col col-3 mb-3">
            <label htmlFor="phoneInput" className="form-label">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              className="form-control"
              id="phoneInput"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: RegexUtils.PHONE,
                  message: "Phone is invalid",
                },
              })}
              onKeyUp={(e) => {
                setValue("phone", StringUtils.formatPhoneNumber(e));
              }}
            />
            <InputErrorMessage name="phone" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="primaryTherapistInput" className="form-label">
              Primary Therapist
            </label>
            <input
              type="text"
              name="primaryTherapist"
              className="form-control"
              id="primaryTherapistInput"
              {...register("primaryTherapist", {
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Primary Therapist is invalid",
                },
              })}
            />
            <InputErrorMessage name="primaryTherapist" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="countryCode" className="form-label">
              Country Code
            </label>
            <select
              className="form-select"
              id="countryCodeInput"
              aria-label="Default select example"
              {...register("countryCode", {
                required: "Country Code is required",
                minLength: {
                  value: 2,
                  message: "Country Code must exceed 2 characters",
                },
                maxLength: {
                  value: 5,
                  message: "Country Code must not exceed 5 characters",
                },
              })}
            >
              <option></option>
              {COUNTRY_CODES_LIST.map((item, index) => (
                <option key={item.value + index} value={item.value}>
                  {item.country}
                </option>
              ))}
            </select>
            <InputErrorMessage name="countryCode" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="associatedHospitalIdInput" className="form-label">
              Associated Centre
            </label>
            <select
              className="form-select"
              name="associatedHospitalId"
              id="associatedHospitalIdInput"
              aria-label="Default select example"
              {...register("associatedHospitalId", {
                required: "Associated Centre is required",
              })}
            >
              <option></option>
              {hospitals.map((hospital) => (
                <option key={hospital.id} value={hospital.id}>
                  {hospital.hospitalName}
                </option>
              ))}
            </select>
            <InputErrorMessage name="associatedHospitalId" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="addressLine1Input" className="form-label">
              Address Line 1
            </label>
            <input
              type="text"
              className="form-control"
              id="addressLine1Input"
              {...register("addressLine1", {
                required: "Address Line 1 is required",
              })}
            />
            <InputErrorMessage name="addressLine1" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="addressLine2Input" className="form-label">
              Address Line 2
            </label>
            <input
              type="text"
              className="form-control"
              id="addressLine2Input"
              {...register("addressLine2", {
                required: "Address Line 2 is required",
              })}
            />
            <InputErrorMessage name="addressLine2" errors={errors} />
          </div>
          <div className="col col-4">
            <label htmlFor="zipcodeInput" className="form-label">
              Zipcode
            </label>
            <input
              type="text"
              className="form-control"
              id="zipcodeInput"
              {...register("zipcode", {
                required: "Zipcode is required",
                pattern: {
                  value: RegexUtils.NUMERIC,
                  message: "Zipcode should be numeric",
                },
                minLength: {
                  value: 4,
                  message: "Zipcode must exceed 4 characters",
                },
                maxLength: {
                  value: 6,
                  message: "Zipcode must not exceed 6 characters",
                },
              })}
              onBlur={updateLocation}
            />
            <InputErrorMessage name="zipcode" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="cityInput" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="cityInput"
              {...register("city", {
                required: "City is required",
              })}
            />
            <InputErrorMessage name="city" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="stateInput" className="form-label">
              State
            </label>
            <input
              type="text"
              className="form-control"
              id="stateInput"
              {...register("state", {
                required: "State is required",
              })}
            />
            <InputErrorMessage name="state" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="countryInput" className="form-label">
              Country
            </label>
            <input
              type="text"
              name="country"
              className="form-control"
              id="countryInput"
              {...register("country", {
                required: "Country is required",
              })}
            />
            <InputErrorMessage name="country" errors={errors} />
          </div>
        </div>
        {updatePatientDetailsAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="me-3"
          >
            Update
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            className="me-3"
            startIcon={<DoneIcon />}
            variant="contained"
          >
            Update
          </Button>
        )}
      </form>
      <Divider className="my-3">
        <Chip label="Parents" />
      </Divider>
      <div>
        <div className="d-flex justify-content-end">
          <Button
            size="small"
            onClick={() => {
              setPatientParentList((prevState) => [...prevState, {}]);
            }}
            startIcon={<AddIcon color="primary" />}
            variant="outlined"
          >
            Add Parent
          </Button>
        </div>
        <div className="mt-3 d-flex flex-wrap justify-content-between">
          {patientParentList.map((parent, index) => {
            return (
              <ParentCard
                key={`parent-card-${index}`}
                index={index}
                isAddingParent={Object.keys(parent).length === 0}
                hospitalId={props.hospitalId}
                patientId={props.patientId}
                parentData={parent}
                refreshParentsListHandler={() => {
                  setPatientParentList([]);
                  getPatientParents();
                }}
              />
            );
          })}
        </div>
      </div>
    </Paper>
  );
}

function Biometrics(props) {
  const theme = useTheme();

  const getPatientBiometricsAPI = useAPI(
    PatientService.getPatientBiometrics,
    getPatientBiometricsHandler
  );
  const updatePatientBiometricsAPI = useAPI(
    PatientService.updatePatientBiometrics,
    () => {},
    true
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    getPatientBiometricsAPI.execute(props.hospitalId, props.patientId);
  }, []);

  function getPatientBiometricsHandler(response) {
    let data = response.data;
    for (let key in data) {
      if (data[key] == null) data[key] = "";
    }
    reset(data);
  }

  function updatePatientBiometrics(data) {
    updatePatientBiometricsAPI.execute(props.hospitalId, props.patientId, data);
  }

  return (
    <Paper elevation={0}>
      <form
        className="container py-4 px-4"
        onSubmit={handleSubmit(updatePatientBiometrics)}
      >
        <div className="row mb-4">
          <div className="col col-4 mb-3">
            <label htmlFor="heightInput" className="form-label">
              Height{" "}
              <span>
                <Typography variant="caption" color={theme.palette.text.main}>
                  (in Cms)
                </Typography>
              </span>
            </label>
            <input
              type="text"
              name="height"
              className="form-control"
              id="heightInput"
              {...register("height", {
                required: "Height is required",
                pattern: {
                  value: RegexUtils.NUMERIC,
                  message: "Height is invalid",
                },
              })}
            />
            <InputErrorMessage name="height" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="weightInput" className="form-label">
              Weight{" "}
              <span>
                <Typography variant="caption" color={theme.palette.text.main}>
                  (in Lbs)
                </Typography>
              </span>
            </label>
            <input
              type="text"
              name="weight"
              className="form-control"
              id="weightInput"
              {...register("weight", {
                required: "Weight is required",
                pattern: {
                  value: RegexUtils.NUMERIC,
                  message: "Weight is invalid",
                },
              })}
            />
            <InputErrorMessage name="weight" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="bmiInput" className="form-label">
              BMI
            </label>
            <input
              type="text"
              name="bmi"
              className="form-control"
              id="bmiInput"
              {...register("bmi", {
                required: "BMI is required",
                pattern: {
                  value: RegexUtils.NUMERIC,
                  message: "BMI is invalid",
                },
              })}
            />
            <InputErrorMessage name="bmi" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label className="form-label d-block">Deficit</label>
            <div className="form-check form-check-inline mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="deficit"
                id="deficitInputLeft"
                value="Left"
                {...register("deficit", {
                  required: "Deficit is required",
                })}
              />
              <label className="form-check-label" htmlFor="deficitInputLeft">
                Left
              </label>
            </div>
            <div className="form-check form-check-inline mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="deficit"
                id="deficitInputRight"
                value="Right"
                {...register("deficit", {
                  required: "Deficit is required",
                })}
              />
              <label className="form-check-label" htmlFor="deficitInputRight">
                Right
              </label>
            </div>
            <div className="form-check form-check-inline mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="deficit"
                id="deficitInputNotApplicable"
                value="NA"
                {...register("deficit", {
                  required: "Deficit is required",
                })}
              />
              <label
                className="form-check-label"
                htmlFor="deficitInputNotApplicable"
              >
                NA
              </label>
            </div>
            <InputErrorMessage name="deficit" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="hairColorInput" className="form-label">
              Hair Color
            </label>
            <input
              type="text"
              name="hairColor"
              className="form-control"
              id="hairColorInput"
              {...register("hairColor", {
                required: "Hair Color is required",
              })}
            />
            <InputErrorMessage name="hairColor" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="eyeColorInput" className="form-label">
              Eye Color
            </label>
            <input
              type="text"
              name="eyeColor"
              className="form-control"
              id="eyeColorInput"
              {...register("eyeColor", {
                required: "Eye Color is required",
              })}
            />
            <InputErrorMessage name="eyeColor" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="bloodGroupInput" className="form-label">
              Blood Group
            </label>
            <select
              className="form-select"
              name="bloodGroup"
              id="bloodGroupInput"
              aria-label="Default select example"
              {...register("bloodGroup", {
                required: "Blood Group is required",
              })}
            >
              <option>Select Group</option>
              <option value="A+">A+</option>
              <option value="A+">A-</option>
              <option value="B-">B+</option>
              <option value="B+">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </select>
          </div>
          <InputErrorMessage name="bloodGroup" errors={errors} />
        </div>
        {updatePatientBiometricsAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="me-3"
          >
            Update
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            className="me-3"
            startIcon={<DoneIcon />}
            variant="contained"
          >
            Update
          </Button>
        )}
      </form>
    </Paper>
  );
}

function Forms(props) {
  let navigate = useNavigate();
  const [formsList, setFormsList] = useState([]);
  const getFormsAPI = useAPI(
    PatientService.getRegistrationForms,
    getRegistrationFormsHandler
  );

  const tableHeadings = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "formType",
      label: "Form Type",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "updatedAt",
      label: "Updated At",
    },
    {
      id: "view",
      label: "",
    },
  ];

  useEffect(() => {
    getFormsAPI.execute(props.hospitalId, props.patientId);
  }, []);

  function getRegistrationFormsHandler(response) {
    for (let form of response.data) {
      formsList.push(transformJsonData(form));
    }
    setFormsList(formsList);
  }

  return (
    <Paper elevation={0}>
      {getFormsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              my: 20,
            }}
          />
        </div>
      ) : (
        <EnhancedTable
          headings={tableHeadings}
          rowsData={formsList}
          rowsPerPage={10}
        />
      )}
    </Paper>
  );

  function transformJsonData(form) {
    return {
      id: form.id,
      form_type: EnumUtils.parse(form.registrationFormTypeEnum),
      status: FormUtils.getStatusChip(form.formStatusTypeEnum),
      updatedAt: DateUtils.formatDateTime(form.lastUpdatedAt),
      actions: <ActionsCell hospitalId={props.hospitalId} form={form} />,
    };
  }

  function ActionsCell({ hospitalId, form }) {
    const revokeFormSubmissionAPI = useAPI(
      PatientService.revokeRegistrationFormSubmission,
      revokeFormSubmissionHandler,
      true
    );

    const actions = {
      startItems: [
        {
          component: <VisibilityIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () =>
            navigateToForm(
              form.registrationFormTypeEnum,
              hospitalId,
              props.patientId,
              form.id
            ),
        },
      ],
      menuItems: [],
    };

    // Add "revoke submission" option only when the form is submitted
    if (form.formStatusTypeEnum === "SUBMITTED") {
      actions.menuItems.push({
        label: "Revoke Submission",
        onClickHandler: () =>
          revokeFormSubmission(hospitalId, props.patientId, form.id),
      });
    }

    function revokeFormSubmission(hospitalId, patientId, formId) {
      revokeFormSubmissionAPI.execute(hospitalId, patientId, formId);
    }

    function revokeFormSubmissionHandler(response) {
      navigate(0);
    }

    function navigateToForm(
      patientFormTypeEnum,
      hospitalId,
      patientId,
      registrationFormId
    ) {
      if (patientFormTypeEnum === "DEMOGRAPHIC_INFO_CHECKLIST_CHILD") {
        window.open(
          `user-forms/patient-registration/child-demographic-info-checklist.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "HISTORY_CHILD") {
        window.open(
          `user-forms/patient-registration/child-history.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "HEMISPHERIC_CHECKLIST_CHILD") {
        window.open(
          `user-forms/patient-registration/child-hemispheric-checklist.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "DEMOGRAPHIC_INFO_CHECKLIST_ADULT") {
        window.open(
          `user-forms/patient-registration/adult-demographic-info-checklist.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "HISTORY_ADULT") {
        window.open(
          `user-forms/patient-registration/adult-history.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "HEMISPHERIC_CHECKLIST_ADULT") {
        window.open(
          `user-forms/patient-registration/adult-hemispheric-checklist.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "COGNITIVE_STYLE_ASSESSMENT_ADULT") {
        window.open(
          `user-forms/patient-registration/adult-cognitive-style-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "SENSORY_FUNCTION_CHECKLIST_ADULT") {
        window.open(
          `user-forms/patient-registration/adult-sensory-function-checklist.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else if (patientFormTypeEnum === "PATIENT_CONSENT") {
        window.open(
          `user-forms/patient-registration/patient-consent.html?hospitalID=${hospitalId}&patientID=${patientId}&registrationFormID=${registrationFormId}&viewMode=read`,
          "_blank"
        );
      } else {
        alert("Invalid Patient Assessment Form Type");
      }
    }

    return <TableRowActionsCell actions={actions} />;
  }
}

function Documents(props) {
  const [documentsList, setDocumentsList] = useState([]);
  const inputFileRef = useRef(null);
  const popUpModalRef = useRef(null);
  const [popUpModalContent, setPopUpModalContent] = useState({
    documentId: "",
  });
  const [documentIdToBeDeleted, setDocumentIdToBeDeleted] = useState();

  const getDocumentsAPI = useAPI(
    PatientService.getPatientDocuments,
    getDocumentsHandler
  );
  const uploadDocumentAPI = useAPI(
    PatientService.uploadPatientDocument,
    uploadDocumentHandler,
    true
  );
  const deleteDocumentAPI = useAPI(
    PatientService.deletePatientDocument,
    deleteDocumentHandler,
    true
  );

  const tableHeadings = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "fileName",
      label: "File Name",
    },
    {
      id: "uploadedAt",
      label: "Uploaded At",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const getDocuments = useCallback(() => {
    getDocumentsAPI.execute(props.hospitalId, props.patientId);
  }, []);

  useEffect(() => {
    getDocuments();
  }, []);

  function uploadDocumentHandler(response) {
    getDocuments();
  }

  function deleteDocument(documentId) {
    deleteDocumentAPI.execute(props.hospitalId, props.patientId, documentId);
    setDocumentIdToBeDeleted(documentId);
  }

  function uploadDocument(file) {
    var formData = new FormData();
    formData.append("document", file);
    uploadDocumentAPI.execute(props.hospitalId, props.patientId, formData);
  }

  function getDocumentsHandler(response) {
    let freshDocumentsList = [];
    for (let form of response.data) {
      freshDocumentsList.push(transformJsonData(form));
    }
    setDocumentsList(freshDocumentsList);
  }

  function deleteDocumentHandler(response) {
    setDocumentsList((prevState) => {
      let documentListIndex = prevState.findIndex(
        (document) => document.id === documentIdToBeDeleted
      );
      prevState.splice(documentListIndex, 1);
      return [...prevState];
    });
  }

  const onFileChange = (e) => {
    /* Selected files data can be collected here. */

    uploadDocument(e.target.files[0]);
  };
  const onUploadButtonClick = () => {
    /* Collecting node-element and performing click */
    inputFileRef.current.click();
  };

  function openModal() {
    popUpModalRef.current.openModal();
  }

  function closeModal() {
    popUpModalRef.current.closeModal();
  }

  return (
    <Paper elevation={0}>
      <div className="pt-3 text-end">
        {uploadDocumentAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<FileUploadIcon />}
            variant="contained"
            className="me-3"
            color="secondary"
          >
            Upload File
          </LoadingButton>
        ) : (
          <Button
            onClick={onUploadButtonClick}
            className="me-3"
            startIcon={<FileUploadIcon />}
            variant="contained"
            color="secondary"
          >
            Upload File
          </Button>
        )}

        <input
          type="file"
          ref={inputFileRef}
          onChangeCapture={onFileChange}
          hidden="hidden"
        />
      </div>
      {getDocumentsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress className="text-center" />
        </div>
      ) : (
        <EnhancedTable
          headings={tableHeadings}
          rowsData={documentsList}
          rowsPerPage={10}
        />
      )}
      <PopUpModal ref={popUpModalRef}>
        <DeleteDocumentModal documentId={popUpModalContent.documentId} />
      </PopUpModal>
    </Paper>
  );

  function transformJsonData(form) {
    return {
      id: form.id,
      fileName: form.originalFilename,
      uploadedAt: form.lastUpdatedAt,
      actions: <ActionsCell documentId={form.id} link={form.s3FileUrl} />,
    };
  }

  function ActionsCell({ documentId, link }) {
    const actions = {
      startItems: [
        {
          component: <OpenInNewIcon fontSize="inherit" color="primary" />,
          onClickHandler: () => window.open(link),
        },
        {
          component: <DeleteOutlineIcon fontSize="inherit" color="error" />,
          onClickHandler: () => {
            setPopUpModalContent({
              documentId,
            });
            openModal();
          },
        },
      ],
      menuItems: [],
    };
    return <TableRowActionsCell actions={actions} />;
  }

  function DeleteDocumentModal({ documentId }) {
    return (
      <Box
        className="px-3 py-4"
        sx={{
          maxWidth: "350px",
        }}
      >
        <Typography id="modal-modal-title" variant="h3" color="text">
          Do you want to confirm deleting the document?
        </Typography>
        <Stack direction="row" spacing={3} className="mt-4">
          <Button
            onClick={() => {
              deleteDocument(documentId);
              closeModal();
            }}
            variant="contained"
          >
            Confirm
          </Button>
          <Button onClick={() => closeModal()} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  }
}

function ParentCard({
  index,
  hospitalId,
  patientId,
  isAddingParent,
  parentData,
  refreshParentsListHandler,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    if (!isAddingParent) {
      reset(parentData);
    }
  }, [isAddingParent]);

  const addPatientParentAPI = useAPI(
    PatientService.addPatientParent,
    () => {
      refreshParentsListHandler();
    },
    true
  );

  const updatePatientParentAPI = useAPI(
    PatientService.updatePatientParent,
    () => {},
    true
  );

  const removePatientParentAPI = useAPI(
    PatientService.removePatientParent,
    () => {
      refreshParentsListHandler();
    }
  );

  function addPatientParent(data) {
    addPatientParentAPI.execute(hospitalId, patientId, data);
  }

  function updatePatientParent(data) {
    updatePatientParentAPI.execute(hospitalId, patientId, parentData.id, data);
  }

  function removePatientParent() {
    if (isAddingParent) {
      refreshParentsListHandler();
      return;
    }
    removePatientParentAPI.execute(hospitalId, patientId, parentData.id);
  }

  return (
    <Card variant="outlined" className="mb-3">
      <form
        onSubmit={handleSubmit(
          isAddingParent ? addPatientParent : updatePatientParent
        )}
      >
        <CardContent>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="mb-3">
              <label
                htmlFor={`parentFirstNameInputs-${index}`}
                className="form-label"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                id={`parentFirstNameInputs-${index}`}
                {...register("firstName", {
                  required: "First Name is required",
                  pattern: {
                    value: RegexUtils.NAME,
                    message: "Fist Name is invalid",
                  },
                })}
              />
              <InputErrorMessage name="firstName" errors={errors} />
            </div>
            <div className="mb-3">
              <label
                htmlFor={`parentLastNameInput-${index}`}
                className="form-label"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                id={`parentLastNameInput-${index}`}
                {...register("lastName", {
                  required: "Last Name is required",
                  pattern: {
                    value: RegexUtils.NAME,
                    message: "Last Name is invalid",
                  },
                })}
              />
              <InputErrorMessage name="lastName" errors={errors} />
            </div>
            <div className="mb-3">
              <label
                htmlFor={`parentEmailInput-${index}`}
                className="form-label"
              >
                Email ID
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                id={`parentEmailInput-${index}`}
                {...register("email", {
                  required: "Email ID is required",
                  pattern: {
                    value: RegexUtils.EMAIL,
                    message: "Email ID is invalid",
                  },
                })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phoneInput" className="form-label">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                id="phoneInput"
                {...register("phone", {
                  required: "Phone is required",
                  pattern: {
                    value: RegexUtils.PHONE,
                    message: "Phone is invalid",
                  },
                })}
                onKeyUp={(e) => {
                  setValue("phone", StringUtils.formatPhoneNumber(e));
                }}
              />
              <InputErrorMessage name="phone" errors={errors} />
            </div>
            <div className="mb-3">
              <label htmlFor="countryCode" className="form-label">
                Country Code
              </label>
              <select
                className="form-select"
                id="countryCodeInput"
                aria-label="Default select example"
                {...register("countryCode", {
                  required: "Country Code is required",
                  minLength: {
                    value: 2,
                    message: "Country Code must exceed 2 characters",
                  },
                  maxLength: {
                    value: 5,
                    message: "Country Code must not exceed 5 characters",
                  },
                })}
              >
                <option></option>
                {COUNTRY_CODES_LIST.map((item, index) => (
                  <option key={item.value + index} value={item.value}>
                    {item.country}
                  </option>
                ))}
              </select>
              <InputErrorMessage name="countryCode" errors={errors} />
            </div>
            <div className="mb-3">
              <label className="form-label d-block">Gender</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id={`parentGenderInputMale-${index}`}
                  value="Male"
                  {...register("gender", {
                    required: "Gender is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor={`parentGenderInputMale-${index}`}
                >
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id={`parentGenderInputFemale-${index}`}
                  value="Female"
                  {...register("gender", {
                    required: "Gender is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor={`parentGenderInputFemale-${index}`}
                >
                  Female
                </label>
              </div>
              <InputErrorMessage name="gender" errors={errors} />
            </div>
            <div>
              <label
                htmlFor={`parentMaritalStatusInput-${index}`}
                className="form-label"
              >
                Marital Status
              </label>
              <select
                className="form-select"
                name="maritalStatus"
                id={`parentMaritalStatusInput-${index}`}
                aria-label="Default select example"
                {...register("maritalStatus", {
                  required: "Marital Status is required",
                })}
              >
                <option></option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="NA">NA</option>
              </select>
              <InputErrorMessage name="maritalStatus" errors={errors} />
            </div>
          </div>
        </CardContent>
        <CardActions>
          <Button size="small" type="submit">
            {isAddingParent ? "Add" : "Save"}
          </Button>
          <Button
            size="small"
            color="error"
            onClick={() => removePatientParent()}
          >
            Remove
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

export default PatientProfile;
